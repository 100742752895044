<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="640"
      scrollable
      :loading="loading"
    >
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title>Präsentation hochladen *T</v-card-title>
        <v-card-subtitle>{{ getSubtitle }}</v-card-subtitle>
        <v-card-text v-if="page === 1">
          <v-row dense>
            <v-col cols="12" class="mt-2">
              <v-form v-model="valid">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  :rules="rules.title"
                  :label="$t('resources.nuggets.title')"
                  :disabled="loading || uploading"
                  background-color="white"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="12">
              <span class="caption"></span>
              <v-file-input
                v-model="newFile"
                outlined
                dense
                accept=".ppt,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                background-color="white"
                label="Neue Datei wählen *T"
              />
            </v-col>
          </v-row>
          <v-alert v-if="uploadError" type="error">UploadError *T</v-alert>
        </v-card-text>

        <v-card-text v-if="page === 2">
          <v-alert v-if="uploading" type="info" text>
            <template #prepend>
              <v-progress-circular width="3" indeterminate />
            </template>
            <span class="pl-4">Deine Datei wird gerade verarbeitet. *T</span>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="page === 3 && !source_is_patched">
          <!-- <v-alert v-if="!source || (source && !source.pages)"
            >no pages *T</v-alert
          > -->
          <v-row dense>
            <v-col cols="12" class="mt-2">
              <v-form v-model="valid">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  :rules="rules.title"
                  :label="$t('resources.nuggets.title')"
                  :disabled="loading || uploading"
                  background-color="white"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-item-group
            v-if="source && source.pages"
            v-model="activePages"
            multiple
          >
            <v-row>
              <v-col
                v-for="(page, idx) in source.pages"
                :key="`page-${idx}`"
                cols="12"
                md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    outlined
                    style="overflow: hidden"
                    height="120"
                    @click="toggle"
                  >
                    <v-img :src="page.img" contain max-height="120">
                      <div class="d-flex justify-space-between pa-2">
                        <v-chip
                          color="secondary"
                          small
                          class="pr-2 elevation-4"
                        >
                          <span>{{ page.number }}</span>
                          <v-avatar
                            right
                            :color="active ? 'success' : 'error'"
                            size="12"
                            style="margin-right: -8px"
                          >
                            <v-icon x-small>{{
                              active ? "mdi-check" : "mdi-close"
                            }}</v-icon>
                          </v-avatar>
                        </v-chip>
                        <v-spacer />
                        <SlidePreviewDialog
                          :slide="page"
                          :slide-count="source.pages.length"
                          :selected="active"
                        >
                          <template #action="{ openDialog }">
                            <v-btn
                              fab
                              color="info"
                              x-small
                              @click.stop="openDialog()"
                            >
                              <v-icon> mdi-magnify </v-icon>
                            </v-btn>
                          </template>
                        </SlidePreviewDialog>
                      </div>
                    </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>

        <v-card-text v-if="page === 4">
          <v-alert type="info" text>
            <template #prepend>
              <v-progress-circular width="3" indeterminate />
            </template>
            <span class="pl-4">Deine Datei wird gerade verarbeitet. *T</span>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn
            small
            text
            :disabled="loading || uploading"
            @click="closeDialog()"
            >{{ $t("general.close") }}</v-btn
          >
          <v-spacer />
          <v-btn
            small
            text
            :disabled="page <= 1 || loading || uploading"
            @click="page--"
            >{{ $t("general.back") }}</v-btn
          >
          <v-btn
            small
            text
            :disabled="pageUpDisabled"
            @click="handlePageUp()"
            >{{ page < 3 ? $t("general.next") : $t("general.apply") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SlidePreviewDialog from "@/components/files/SlidePreviewDialog";

export default {
  name: "PresentationUploadDialog",
  components: { SlidePreviewDialog },
  props: {
    mimetype: {
      type: String, // filter for list
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedFileSource: null,
      newFile: null,
      page: 1,
      uploadError: false,
      uploading: false,
      progress: null,
      uploadedFile: null,
      activePages: [],
      source_is_patched: false,
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
        ],
      },
      title: "",
      valid: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { source: "get_source" }),
    pageUpDisabled() {
      var disabled = false;
      if (this.page > 4 || this.loading || this.uploading) disabled = true;
      if (this.page === 1 && (!this.newFile || !this.valid)) disabled = true;
      if (this.page === 3 && this.activePages.length < 1) disabled = true;
      if (this.page === 4 && !this.source) disabled = true;
      return disabled;
    },
    getSubtitle() {
      var texts = [
        "Wähle eine Datei und Lade sie hoch. *T",
        "Dateivorschau *T",
        "Slide-Auswahl. Es kann ein wenig dauern ehe die Vorschaubilder der Slides sichtbar sind. *T",
        "Bestätigen *T",
      ];
      return `Schritt: ${this.page}: ${texts[this.page - 1]}`;
    },
  },
  methods: {
    ...mapActions("files", [
      "fetch_upload_token",
      "upload_file",
      "create_source_from_file",
      "fetch_file",
    ]),
    ...mapActions("ghostwriter", [
      "fetch_source",
      "patch_source",
      "fetch_fileserver_job",
    ]),
    ...mapMutations("files", ["set_files"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.newFile = null;
      this.page = 1;
      this.activePages = [];
      this.source_is_patched = false;
      this.title = "";
    },
    async uploadFile() {
      this.loading = true;
      this.uploadError = false;
      this.uploading = true;

      var res_token = await this.fetch_upload_token();

      if (res_token._status !== 200 || !res_token.token) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        return false;
      }

      var res = await this.upload_file({
        payload: {
          file: this.newFile,
          token: res_token.token,
          lang: "de-DE", // TODO: remove hardcoded value
        },
        refetch: false,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
      if (res._status === 200) {
        this.uploadedFile = res.file;
        this.checkFileJob(res.job.job_id);
        this.$notify({
          type: "success",
          title: "Upload erfolgreich *T", //this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        this.uploadError = true;
      }

      this.loading = false;
    },
    async checkFileJob(id) {
      var res = await this.fetch_fileserver_job({
        id: id,
      });
      if (
        !res ||
        (res &&
          res.job &&
          (res.job.status === "SUCCESS" || res.job.status === "FAILURE"))
      ) {
        this.$notify({
          type: "success",
          title: "File scheint Variants zu haben *T", //this.$t("general.success"),
        });
        this.cancelJob();
        this.createSource();
        return false;
      }
      if (!res.job || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.checkFileJob(id);
        }.bind(this),
        2000
      );
    },
    async checkSource(id) {
      var res = await this.fetch_source({
        id: id,
      });
      if (!res || (res && res.source.pages && res.source.pages.length > 0)) {
        this.cancelJob();
        this.uploading = false;
        this.setActivePages();
        if (this.source_is_patched) this.applySource();
        return false;
      }
      if (!res.source || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.checkSource(id);
        }.bind(this),
        2000
      );
    },
    async cancelJob() {
      this.uploading = false;
      this.progress = clearInterval(this.progress);
    },
    async createSource() {
      this.loading = true;
      this.uploading = true;
      var payload = {
        title: this.title,
        lang: "de-DE",
        file_id: this.uploadedFile.id,
      };
      var res = await this.create_source_from_file({ payload: payload });
      this.uploading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Source erstellt *T", //this.$t("general.success"),
        });
        this.page = 3;
        this.checkSource(res.source.id);
        console.log(res.source);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    handlePageUp() {
      if (this.page === 1) this.uploadFile();
      if (this.page === 3) {
        this.patchSource();
      }
      this.page++;
    },
    async patchSource() {
      this.loading = true;
      var pages = [...this.activePages].sort((a, b) => a - b).map((p) => p + 1);
      var res = await this.patch_source({
        payload: {
          pages: pages,
          title: this.title,
          lang: this.source.lang,
        },
        id: this.source.id,
        config: null,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Source bearbeitet *T", //this.$t("general.success"),
        });
        this.source_is_patched = true;
        /* this.page = 4; */
        this.checkSource(res.source.id);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }

      this.$notify({
        type: res && res._status === 200 ? "success" : "error",
        title:
          res && res._status === 200
            ? this.$t("general.success")
            : this.$t("general.error"),
      });
    },
    applySource() {
      this.closeDialog();
      this.$emit("filesource", this.source);
    },
    setActivePages() {
      if (!this.source || !this.source.pages) return false;
      var pages = [...this.source.pages];
      pages.forEach((_, i) => this.activePages.push(i));
    },
  },
};
</script>
