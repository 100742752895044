<template>
  <div>
    <ViewHeader :breadcrumbs="breadcrumbs" :title="title" icon="mdi-typewriter">
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'GwSessionBulk' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>

      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading || !journey" />

    <section v-if="!loading && journey" class="mt-8">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" lg="3">
            <v-row dense>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  small
                  :disabled="
                    journey.is_imported ||
                    journey.is_deleted ||
                    journey.is_archived ||
                    hasNoOpenContent
                  "
                  @click="acceptContent()"
                >
                  <span>{{ $t("gw.journey.accept_btn") }}</span>
                  <v-icon small class="ml-2">mdi-check-circle</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <GwJourneyImportDialog :journey="journey">
                  <template #button="{ openDialog }">
                    <v-tooltip right disabled>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-btn
                            block
                            color="primary"
                            :disabled="
                              journey.is_imported ||
                              journey.is_deleted ||
                              journey.is_archived
                            "
                            @click="openDialog()"
                          >
                            <span>{{ $t("gw.journey.import_btn") }}</span>
                            <v-icon small class="ml-2">mdi-import</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Hinweistext</span>
                    </v-tooltip>
                  </template>
                </GwJourneyImportDialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card outlined tile>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-list class="py-0" style="transition: 0.8s">
                          <v-list-item dense>
                            <v-list-item-action>
                              <CountryFlag
                                :country="flagName(journey.lang)"
                                size="normal"
                                class="mb-1"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                $t(`languages.${journey.lang}.display`)
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                $t("resources.nuggets.language")
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12">
                        <v-list class="py-0">
                          <v-list-item dense>
                            <v-list-item-action>
                              <v-avatar color="grey lighten-3" size="32">
                                <v-icon small>mdi-circle-multiple</v-icon>
                              </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                journey.credits.toLocaleString()
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                $t("gw.licences.credits")
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <v-col cols="12">
                        <!-- <v-divider class="mb-2" /> -->
                        <v-list class="py-0">
                          <v-list-item dense>
                            <v-list-item-action>
                              <v-avatar color="grey lighten-3" size="32">
                                <v-icon small>mdi-shape</v-icon>
                              </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                categoryName
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                $t("resources.categories.singular")
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <GwJourneyCategoryChanger :journey="journey">
                                <template #button="{ openDialog }">
                                  <v-badge
                                    :value="!journey.category_id"
                                    bordered
                                    icon="mdi-alert"
                                    color="error"
                                    overlap
                                  >
                                    <v-btn
                                      fab
                                      x-small
                                      elevation="1"
                                      color="primary"
                                      :disabled="
                                        journey.is_imported ||
                                        journey.is_deleted ||
                                        journey.is_archived
                                      "
                                      @click="openDialog()"
                                    >
                                      <v-icon small>mdi-tag-edit</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </template>
                              </GwJourneyCategoryChanger>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <!-- <v-divider class="my-2" /> -->
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined tile>
                  <v-card-text>
                    <GwJourneyFilter />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" lg="9">
            <v-slide-x-transition hide-on-leave>
              <router-view />
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import CountryFlag from "vue-country-flag";
import GwJourneyFilter from "@/components/ghostwriter/journeys/GwJourneyFilter";
import GwJourneyCategoryChanger from "@/components/ghostwriter/journeys/GwJourneyCategoryChanger";
import GwJourneyImportDialog from "@/components/ghostwriter/journeys/GwJourneyImportDialog";

export default {
  name: "GwJourneyDetail",
  components: {
    ViewHeader,
    ViewLoader,
    GwCreditInfoMenu,
    ViewNavigation,
    CountryFlag,
    GwJourneyFilter,
    GwJourneyCategoryChanger,
    GwJourneyImportDialog,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      progress: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      journey_filter: "get_journey_filter",
      job: "get_job",
    }),
    ...mapGetters("categories", { categories: "get_categories" }),
    title() {
      if (!this.journey)
        return `${this.$t("gw.ghostwriter")}: ${this.$t(
          "resources.journeys.journey.singular"
        )}`;
      return `${this.$t("resources.journeys.journey.singular")}: ${
        this.journey.title
      }`;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.ghostwriter"),
          disabled: false,
          to: { name: "GwSessionList" },
        },
        {
          text: `${this.$t("resources.journeys.journey.plural")} (beta)`,
          disabled: false,
          to: { name: "GwJourneys" },
        },
        {
          text: this.$t("general.details"),
          disabled: true,
        },
      ];
    },
    navItems() {
      //if (!this.session) return [];
      return [
        {
          title: this.$t("navigation.content.title"),
          link: { name: "GwJourneyContent" },
          //disabled: this.loading || [1, 4, 5, 6].includes(this.session.status),
          disabled: !!this.loading,
        },
        {
          title: "Grundlage *T", //this.$t("gw.journey.source"),
          link: { name: "GwJourneySource" },
          //disabled: this.loading || [1, 4, 5, 6].includes(this.session.status),
          disabled: !!this.loading,
        },
        {
          title: this.$t("resources.invitations.settings"),
          link: { name: "GwJourneySettings" },
          //disabled: this.loading || [1, 4, 5, 6].includes(this.session.status),
          disabled: !!this.loading,
        },
      ];
    },
    categoryName() {
      if (!this.categories || !this.journey.category_id) return "-";
      var category = [...this.categories].filter(
        (c) => c.id === this.journey.category_id
      )[0];
      return category.name;
    },
    hasNoOpenContent() {
      var content_count = 0;
      var bundles = { ...this.journey }.bundles;
      bundles.forEach((b) => {
        b.questions.forEach((q) => {
          if (!q.is_accepted && !q.is_deleted) content_count++;
        });
        b.nuggets.forEach((n) => {
          if (!n.is_accepted && !n.is_deleted) content_count++;
        });
      });
      return content_count === 0;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  destroyed() {
    this.set_gw_journey(null);
    this.set_job(null);
  },
  watch: {
    "journey.job": {
      handler: function (v) {
        if (v && v.id) this.checkJob(v.id);
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "fetch_gw_journey",
      "fetch_job",
      "accept_gw_journey_content",
    ]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapActions("auth", ["init_auth"]),
    ...mapMutations("ghostwriter", [
      "set_gw_journey",
      "set_journey_filter",
      "set_job",
    ]),
    async fetchData() {
      this.loading = true;
      await this.fetch_gw_journey({ id: this.id });
      await this.fetch_categories();
      this.loading = false;
    },
    async checkJob(id) {
      await this.fetch_job({
        id: id,
      });
      if (
        !this.journey ||
        !id ||
        (this.job && this.job.status === "SUCCESS") ||
        (this.job && this.job.status === "FAILURE") ||
        this.$route.name !== "GwJourneyContent"
      ) {
        this.cancelJob();
        return false;
      }
      if (!this.journey || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.checkJob(id);
        }.bind(this),
        2000
      );
    },
    async cancelJob() {
      if (this.job && this.job.status !== "FAILURE") {
        this.set_job(null);
        await this.init_auth({ id: this.auth.id });
        await this.fetch_gw_journey({ id: this.id });
      }
      this.progress = clearInterval(this.progress);
    },
    async acceptContent() {
      if (!this.journey || !this.journey.bundles) return false;
      this.loading = true;
      var nugget_ids = [];
      var question_ids = [];
      var bundles = [...this.journey.bundles];
      bundles.forEach((b) => {
        var q = b.questions
          .filter((q) => !q.is_deleted && !q.is_accepted)
          .map((q) => q.id);
        var n = b.nuggets
          .filter((n) => !n.is_deleted && !n.is_accepted)
          .map((n) => n.id);
        nugget_ids = nugget_ids.concat(n);
        question_ids = question_ids.concat(q);
      });

      var payload = {
        nugget_ids: nugget_ids,
        question_ids: question_ids,
      };
      var res = await this.accept_gw_journey_content({
        id: this.journey.id,
        payload: payload,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
  },
};
</script>

<style></style>
