<template>
  <v-card
    outlined
    :color="bgColor"
    :disabled="loading"
    :ripple="false"
    @click.stop
  >
    <v-img
      height="280"
      :src="previewImage && previewImage.image ? previewImage.image : null"
      :key="selectedImage"
      gradient="to top right, rgba(30,30,30,.13), rgba(30,30,30,.4)"
      class="d-flex align-end"
    >
      <v-sheet v-if="!previewImage || !previewImage.image" color="transparent">
        <div class="d-flex flex-column justify-start align-center">
          <v-avatar size="92" tile
            ><v-icon size="92">mdi-image-off</v-icon></v-avatar
          >
          <span>Kein Bild ausgewählt *T</span>
        </div>
      </v-sheet>
      <v-dialog
        v-if="previewImage && previewImage.image"
        v-model="imagePreviewDialog"
        max-width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex justify-end pa-2">
            <v-btn
              small
              fab
              color="info"
              v-bind="attrs"
              v-on="on"
              style="position: absolute; top: 12px; right: 12px"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>

        <v-card color="grey lighten-3">
          <v-card-title class="overline secondary--text">
            Bildvorschau *T
          </v-card-title>

          <v-img :src="previewImage.image" height="600" contain />

          <v-card-actions>
            <v-btn small text @click="imagePreviewDialog = false">
              {{ $t("general.close") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-sheet
        height="80"
        class="d-flex align-center"
        style="position: relative; background-color: rgba(0, 0, 0, 0.4)"
      >
        <v-slide-group v-model="selectedImage" :disabled="loading" show-arrows>
          <v-slide-item
            v-slot="{ active }"
            v-for="(image, i) in selectableImages"
            :key="`img-${i}`"
          >
            <v-card
              :elevation="active ? 8 : 0"
              outlined
              height="60px"
              width="80px"
              class="d-flex align-center ma-2"
              :style="!active ? 'opacity:.65' : ''"
              @click.stop="!active ? patchNugget(image.id) : false"
            >
              <v-img
                v-if="!!image.image"
                :src="image.image"
                height="60px"
                width="80px"
                cover
              >
                <div v-if="active" class="d-flex justify-end ma-1">
                  <v-avatar color="success" size="24">
                    <v-icon small color="white">mdi-check</v-icon>
                  </v-avatar>
                </div>
              </v-img>
              <v-sheet
                v-else
                width="80px"
                height="60px"
                :color="active ? 'grey lighten-3' : 'transparent'"
                class="d-flex flex-column justify-center align-center"
              >
                <v-avatar
                  v-if="active"
                  color="success"
                  size="24"
                  style="position: absolute; top: 4px; right: 4px"
                >
                  <v-icon small color="white">mdi-check</v-icon>
                </v-avatar>

                <v-icon>mdi-image-off</v-icon>
              </v-sheet>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-img>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwNuggetSlideSelector",
  props: {
    images: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, Number],
      required: false,
    },
    nuggetId: {
      type: [String, Number],
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    bgColor: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
  },
  data() {
    return {
      selectedImage: null,
      loading: false,
      imagePreviewDialog: false,
    };
  },
  computed: {
    selectableImages() {
      var images = [{ id: null, image: null }];
      images = images.concat([...this.images]);
      return images;
    },
    previewImage() {
      if (!this.selected || this.selected === 0) return null;
      var images = [...this.selectableImages];
      var image = images.filter((i) => i.id == this.selected)[0];
      return image;
    },
    fallBackImage() {
      /* return require("@/assets/img/misc/default_small.jpg"); */
      return null;
    },
  },
  mounted() {
    this.selectImage();
  },
  methods: {
    ...mapActions("ghostwriter", ["update_gw_journey_nugget"]),
    async patchNugget($e) {
      this.loading = true;
      this.$emit("loading", true);
      this.selectedImage = $e;
      var payload = {
        image:
          !this.selectedImage || this.selectedImage === 0
            ? null
            : this.selectedImage,
      };
      var res = await this.update_gw_journey_nugget({
        payload: payload,
        id: this.nuggetId,
        journey_id: this.journeyId,
      });
      if (res && res._status === 200) {
        this.selectImage();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
      this.$emit("loading", false);
    },
    selectImage() {
      this.selectedImage = 0;
      /* if (!this.selectableImages) this.selectedImage = 0; */

      var selected = this.selectableImages.filter(
        (i) => i.id === this.selected
      )[0];
      if (!selected) this.selectedImage = 0;

      this.selectedImage = selected.id;
      var idx = this.selectableImages.findIndex(
        (i) => i.id == parseInt(selected.id)
      );
      this.selectedImage = idx && idx !== -1 ? idx : 0;
    },
  },
};
</script>

<style lang="scss"></style>
