<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="640"
      scrollable
    >
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title class="overline secondary--text"
          >File source</v-card-title
        >
        <v-card-text v-if="source">
          <v-row>
            <v-col cols="12" class="mt-2">
              <v-text-field
                v-model="title"
                outlined
                dense
                :rules="rules.title"
                :label="$t('resources.nuggets.title')"
                :disabled="loading"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-item-group v-model="activePages" multiple>
            <v-row>
              <v-col
                v-for="(page, idx) in source.pages"
                :key="`page-${idx}`"
                cols="12"
                md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    outlined
                    style="overflow: hidden"
                    height="120"
                    @click="toggle"
                  >
                    <v-img :src="page.img" contain max-height="120">
                      <div class="d-flex justify-space-between pa-2">
                        <v-chip
                          color="secondary"
                          small
                          class="pr-2 elevation-4"
                        >
                          <span>{{ page.number }}</span>
                          <v-avatar
                            right
                            :color="active ? 'success' : 'error'"
                            size="12"
                            style="margin-right: -8px"
                          >
                            <v-icon x-small>{{
                              active ? "mdi-check" : "mdi-close"
                            }}</v-icon>
                          </v-avatar>
                        </v-chip>
                        <v-spacer />
                        <SlidePreviewDialog
                          :slide="page"
                          :slide-count="source.pages.length"
                          :selected="active"
                        >
                          <template #action="{ openDialog }">
                            <v-btn
                              fab
                              color="info"
                              x-small
                              @click.stop="openDialog()"
                            >
                              <v-icon> mdi-magnify </v-icon>
                            </v-btn>
                          </template>
                        </SlidePreviewDialog>
                      </div>
                    </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-btn small text @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            :loading="loading"
            color="primary"
            :disabled="loading || !hasChanges"
            @click="patchSource()"
            >{{ $t("general.edit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SlidePreviewDialog from "@/components/files/SlidePreviewDialog";

export default {
  name: "FileSourceDialog",
  components: { SlidePreviewDialog },
  props: {
    sourceId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      activePages: [],
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
        ],
      },
      title: "",
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { source: "get_source" }),
    hasChanges() {
      if (!this.source) return false;
      var changed = false;
      var pages = this.source.body
        ? { ...this.source }.body.split(",").map((p) => parseInt(p))
        : [];
      var selected = [...this.activePages].sort((a, b) => a - b);
      selected.forEach((p, i) => {
        if (p + 1 !== pages[i]) changed = true;
      });
      if (this.source.title !== this.title) changed = true;
      return changed;
    },
  },
  methods: {
    ...mapActions("files", [
      "fetch_file",
      "fetch_download_token",
      "download_file",
      "create_source_from_file",
    ]),
    ...mapActions("ghostwriter", ["fetch_source", "patch_source"]),
    ...mapMutations("ghostwriter", ["set_source"]),
    openDialog() {
      this.dialog = true;
      this.fetchSource();
    },
    closeDialog() {
      this.dialog = false;
      this.set_source(null);
      this.title = "";
    },
    async fetchSource() {
      if (!this.sourceId) return false;
      this.loading = true;
      await this.fetch_source({ id: this.sourceId });

      if (this.source.body) {
        this.activePages = this.source.body
          .split(",")
          .map((v) => parseInt(v - 1));
      }
      this.title = this.source.title;
      this.loading = false;
    },
    async patchSource() {
      var pages = [...this.activePages].sort((a, b) => a - b).map((p) => p + 1);
      var res = await this.patch_source({
        payload: {
          pages: pages,
          title: this.title,
          lang: this.source.lang,
        },
        id: this.sourceId,
        config: null,
      });

      if (res && res._status === 200) this.$emit("change", res.source);

      this.$notify({
        type: res && res._status === 200 ? "success" : "error",
        title:
          res && res._status === 200
            ? this.$t("general.success")
            : this.$t("general.error"),
      });
    },
  },
};
</script>
