var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('SectionHeader',{attrs:{"title":_vm.$t('resources.journeys.sectionHeaders.editCertificate.title'),"subtitle":_vm.$t('resources.journeys.sectionHeaders.editCertificate.subtitle')}}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("ng.journey.change_certificate_title"))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("ng.journey.change_certificate_subtitle"))+" ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.journey.status !== 'unpublished')?_c('small',{staticClass:"grey--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t("resources.journeys.editWarning")))]):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.visibleCertificates,"item-value":"id","return-object":"","outlined":"","hide-details":"","dense":"","item-text":"name","disabled":_vm.loading ||
                    _vm.journey.status !== 'unpublished' ||
                    !['editor', 'admin', 'superadmin'].includes(_vm.role)},model:{value:(_vm.input.certificate),callback:function ($$v) {_vm.$set(_vm.input, "certificate", $$v)},expression:"input.certificate"}})],1)],1),_c('v-card-actions',{staticClass:"pl-4"},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary","disabled":_vm.loading,"to":{ name: 'JourneyCertificates' },"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_c('span',[_vm._v(_vm._s(_vm.$t("ng.journey.show_all_certificates")))])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"error","disabled":!_vm.inputChanged ||
                  _vm.loading ||
                  !['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.inputChanged ||
                  !_vm.valid ||
                  _vm.loading ||
                  !['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }