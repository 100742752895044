<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="480">
      <v-card v-if="nugget">
        <v-card-title class="overline secondary--text"
          >Nuggetvorschau *T</v-card-title
        >
        <v-card-text>
          <v-img
            :src="getNuggetImageUrl()"
            max-height="200"
            contain
            class="mb-6"
          />
          <h3>{{ nugget.title }}</h3>
          <p>{{ nugget.description }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            :to="{ name: 'NuggetDetail', params: { id: nugget.id } }"
            target="_blank"
            >In neuem Tab anzeigen *T</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NuggetPreviewDialog",
  props: {
    nugget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },

    getNuggetImageUrl() {
      if (!this.nugget.image_hash)
        return require("@/assets/img/misc/default_small.jpg");
      return `${process.env.VUE_APP_MEDIA_SERVER_URL}/i/${this.nugget.image_hash}`;
    },
  },
};
</script>
