<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title>File Upload *T</v-card-title>
        <v-card-text>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa.
          </p>
          <v-row dense>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                outlined
                dense
                background-color="white"
                placeholder="Datei wählen"
                hint="Mögiche Dateitypen: pdf, ppt, abc, efg *T"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="client_id"
                label="client id"
                outlined
                background-color="white"
                dense
                prepend-icon="mdi-domain"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user_id"
                label="user_id"
                outlined
                background-color="white"
                dense
                prepend-icon="mdi-account-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="is_once"
                label="token is_once"
                dense
                class="mt-0"
                persistent-hint
                prepend-icon="mdi-check"
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="pages"
                label="Anzahl Seiten"
                outlined
                background-color="white"
                dense
                type="number"
                :min="1"
                :color="pages === 0 ? 'error' : ''"
                prepend-icon="mdi-file-document"
              />
              <v-alert text color="info"
                >Anzahl der Dokument-Seiten bitte angeben!</v-alert
              >
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn small text @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            :disabled="!file"
            :loading="loading"
            @click="upload()"
            >{{ $t("general.upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FileUploadDialog",
  data() {
    return {
      dialog: false,
      file: null,
      client_id: 1,
      user_id: 101,
      is_once: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions("tmp", ["fetch_upload_token", "upload_file"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.reset();
    },
    reset() {
      this.file = null;
      this.client_id = 1;
      this.user_id = 101;
      this.is_once = true;
      /* this.pages = 1; */
    },
    async upload() {
      this.loading = true;

      var res_token = await this.fetch_upload_token({
        payload: {
          client_id: this.client_id,
          user_id: this.user_id,
          is_once: this.is_once,
        },
      });

      if (res_token._status !== 200 || !res_token.token) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        return false;
      }

      var res = await this.upload_file({
        payload: {
          file: this.file,
          token: res_token.token,
          lang: "de-DE", // TODO: remove hardcoded value
        },
        refetch: true,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });

      this.$notify({
        type: res._status === 200 ? "success" : "error",
        title:
          res._status === 200
            ? this.$t("general.success")
            : this.$t("general.error"),
      });

      if (res._status === 200) this.reset();

      this.loading = false;
    },
  },
};
</script>
