<template>
  <v-card :color="published ? 'success' : ''" :dark="published" outlined>
    <v-card-text>
      <v-stepper
        v-model="stepper"
        non-linear
        value="3"
        alt-labels
        flat
        class="transparent"
      >
        <v-stepper-header>
          <v-tooltip bottom max-width="640" color="info">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-stepper-step
                  :complete="stepper > 1"
                  editable
                  step="1"
                  :color="published ? 'success darken-1' : 'primary darken-1'"
                  complete-icon="mdi-check"
                  error-icon="mdi-close"
                  edit-icon="mdi-check"
                >
                  Add content
                </v-stepper-step>
              </div>
            </template>
            <span
              >Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium. Integer tincidunt.</span
            >
          </v-tooltip>

          <v-divider></v-divider>

          <v-tooltip bottom max-width="640" color="info">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-stepper-step
                  :complete="stepper > 2"
                  editable
                  step="2"
                  :color="published ? 'success darken-1' : 'primary darken-1'"
                  complete-icon="mdi-check"
                  error-icon="mdi-close"
                  edit-icon="mdi-check"
                >
                  Set visibility
                </v-stepper-step>
              </div>
            </template>
            <span
              >Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium. Integer tincidunt.</span
            >
          </v-tooltip>

          <v-divider></v-divider>

          <v-tooltip bottom max-width="640" color="info">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-stepper-step
                  :complete="stepper > 3"
                  editable
                  step="3"
                  :color="published ? 'success darken-1' : 'primary darken-1'"
                  complete-icon="mdi-check"
                  error-icon="mdi-close"
                  edit-icon="mdi-check"
                >
                  Publish
                </v-stepper-step>
              </div>
            </template>
            <span
              >Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium. Integer tincidunt.</span
            >
          </v-tooltip>
        </v-stepper-header>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-if="stepper == 1" small text @click="stepper++">
        <span>Add content</span>
        <v-icon small right>mdi-arrow-right-circle</v-icon>
      </v-btn>
      <v-btn v-if="stepper == 2" small text @click="stepper++">
        <span>Manage visibility</span>
        <v-icon small right>mdi-arrow-right-circle</v-icon>
      </v-btn>
      <v-btn
        v-if="stepper == 3 && !published"
        small
        text
        @click="published = true"
      >
        <span>Publish</span>
        <v-icon small right>mdi-arrow-up-circle</v-icon>
      </v-btn>
      <v-btn
        v-if="stepper == 3 && published"
        small
        outlined
        @click="published = false"
      >
        <span>Unpublish</span>
        <v-icon small right>mdi-arrow-down-circle</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Journey } from "@/models/journeys/Journey";

export default {
  name: "JourneyStepper",
  props: {
    journey: {
      type: Journey,
      required: true,
    },
  },
  data() {
    return {
      stepper: 1,
      published: false,
    };
  },
};
</script>
