<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="showDialog" max-width="640" persistent scrollable>
      <v-form v-model="valid">
        <v-card
          v-if="showDialog"
          color="grey lighten-3"
          max-width="640"
          :loading="loading"
        >
          <v-card-title class="overline secondary--text">
            <span>{{ $t("gw.journey.generate.title") }}</span>
          </v-card-title>
          <v-card-subtitle v-if="!generating_success && !is_generating">
            <p>
              {{ $t("gw.journey.generate.p1") }}
            </p>
            <p>
              {{ $t("gw.journey.generate.p2") }}
            </p>
          </v-card-subtitle>
          <v-card-text v-if="!source_job && !generating_success">
            <v-chip-group v-model="inputMode" mandatory active-class="primary">
              <v-chip small>{{ $t("gw.journey.generate.chips.text") }}</v-chip>
              <v-chip small>{{ $t("gw.journey.generate.chips.pdf") }}</v-chip>
              <v-chip v-show="false" small :disabled="true">{{
                $t("gw.journey.generate.chips.video")
              }}</v-chip>
              <v-chip small>{{
                $t("gw.journey.generate.chips.sources")
              }}</v-chip>
              <v-chip v-if="showPPTFeature" small>PPT *T</v-chip>
            </v-chip-group>
          </v-card-text>
          <v-card-text
            v-if="
              (!source || ([3, 4].includes(inputMode) && !source_job)) &&
              !generating_success
            "
          >
            <v-row v-if="[3].includes(inputMode) && sources">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selected_source"
                  :items="filteredSources"
                  outlined
                  dense
                  item-text="title"
                  return-object
                  clearable
                  :label="$t('general.please_select')"
                  background-color="white"
                  :disabled="loading"
                >
                  <template #item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-avatar>
                        <country-flag
                          :country="flagName(item.lang)"
                          size="small"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template #selection="{ item }">
                    <div class="d-flex align-center">
                      <country-flag
                        :country="flagName(item.lang)"
                        size="small"
                        class="mr-2"
                      />
                      <span>{{ item.title }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="[4].includes(inputMode) && sources" dense class="mb-4">
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="selected_source"
                  :items="filteredFileSources"
                  outlined
                  dense
                  hide-details
                  item-text="title"
                  return-object
                  clearable
                  label="Vorherige Präsentation wählen *T"
                  background-color="white"
                  :disabled="loading && true"
                >
                  <template #item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-avatar>
                        <country-flag
                          :country="flagName(item.lang)"
                          size="small"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template #selection="{ item }">
                    <div class="d-flex align-center">
                      <country-flag
                        :country="flagName(item.lang)"
                        size="small"
                        class="mr-2"
                      />
                      <span>{{ item.title }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="!source" cols="12" md="4">
                <PresentationUploadDialog @filesource="setSource">
                  <template #action="{ openDialog }">
                    <v-btn
                      color="primary"
                      block
                      depressed
                      :disabled="!!selected_source"
                      @click="openDialog()"
                    >
                      <v-icon left>mdi-upload</v-icon>
                      <span>Upload *T</span></v-btn
                    >
                  </template>
                </PresentationUploadDialog>
              </v-col>
              <v-col v-if="!!source" cols="12" md="4">
                <FileSourceDialog
                  :source-id="selected_source ? selected_source.id : null"
                  @change="setSource"
                >
                  <template #action="{ openDialog }">
                    <v-btn
                      color="primary"
                      block
                      depressed
                      :disabled="!selected_source"
                      @click="openDialog()"
                    >
                      <v-icon left>mdi-pencil</v-icon>
                      <span>{{ $t("general.edit") }}</span>
                    </v-btn>
                  </template>
                </FileSourceDialog>
              </v-col>
              <v-col v-if="source" cols="12">
                <v-alert :type="!source.body ? 'error' : 'info'" text>
                  <span v-if="!source.body">No source body!! *T</span>
                  <span v-if="!!source.body">
                    File source from presentation with
                    {{ source.body.split(",").length }} selected pages. *T
                  </span>
                </v-alert>
                <v-divider />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col
                v-if="[0, 1, 2].includes(inputMode)"
                cols="12"
                class="pt-2"
              >
                <v-text-field
                  :value="title"
                  outlined
                  dense
                  :rules="rules.title"
                  :label="$t('resources.nuggets.title')"
                  :disabled="loading || inputMode === 3"
                  background-color="white"
                  @input="setTitle($event)"
                ></v-text-field>
              </v-col>
              <v-col v-if="inputMode === 0" cols="12">
                <v-textarea
                  v-model="txt"
                  :label="$t('resources.nuggets.content')"
                  dense
                  outlined
                  background-color="white"
                  :rules="rules.text"
                  :disabled="loading"
                />
              </v-col>
              <v-col v-if="inputMode === 1" cols="12">
                <v-file-input
                  v-model="selectedFile"
                  ref="gwdocument"
                  accept=".pdf"
                  :label="$t('resources.invitations.fileInput')"
                  outlined
                  :background-color="loading ? 'grey lighten-3' : 'white'"
                  :disabled="loading"
                  small-chips
                  dense
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  :rules="rules.file"
                  clear-icon="mdi-close-circle"
                />
              </v-col>
              <v-col cols="12">
                <LanguageChooserInline
                  colored
                  dense
                  class="pb-0"
                  hidedetails
                  :disabled="loading || !!source || [3, 4].includes(inputMode)"
                  :label="$t('general.language')"
                  :initial="source ? source.lang : lang"
                  @change="changeLanguage"
                />
                <v-alert
                  v-if="inputMode === 4"
                  type="warning"
                  color="warning"
                  class="mt-4 mb-0 caption"
                  dense
                >
                  <!-- {{ $t("gw.bulk.hints.language") }} -->
                  Dieses Feature ist aktuell nur für deutsche Präsentationen
                  verfügbar. *T
                </v-alert>
                <v-alert
                  text
                  type="info"
                  color="info"
                  class="mt-4 mb-0 caption"
                  dense
                  >{{ $t("gw.bulk.hints.language") }}</v-alert
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="source && !source_job && !generating_success">
            <v-row>
              <v-col v-if="inputMode === 0" cols="12">
                <v-text-field
                  :value="title"
                  outlined
                  dense
                  disabled
                  :label="$t('resources.nuggets.title')"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-if="source.mimetype !== 'file'"
                  :value="source.body"
                  :label="$t('resources.nuggets.content')"
                  dense
                  outlined
                  background-color="white"
                  readonly
                  :disabled="loading"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="source.cost.journey.toLocaleString()"
                  outlined
                  dense
                  readonly
                  background-color="grey lighten-2"
                  :label="$t('gw.licences.credits')"
                >
                  <template #prepend-inner>
                    <v-icon color="#ffd700">mdi-circle-multiple</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="currentCreditsCount"
                  outlined
                  dense
                  readonly
                  :label="$t('gw.licences.credits_count')"
                  :background-color="
                    (!licences.ghostwriter.licence.can_overload &&
                      source.cost.journey >
                        licences.ghostwriter.credits_count) ||
                    (licences.ghostwriter.licence.can_overload &&
                      source.cost.journey >
                        licences.ghostwriter.credits_count +
                          licences.ghostwriter.licence.credits_overload)
                      ? 'error'
                      : 'success'
                  "
                >
                  <template #prepend-inner>
                    <v-icon color="#ffd700">mdi-circle-multiple</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-expand-transition>
            <v-card-text v-if="!!source_job && !generating_success">
              <v-alert
                color="secondary"
                prominent
                icon="mdi-information"
                dark
                text
              >
                <p>
                  {{ $t("gw.journey.generate.alert") }}
                </p>
                <v-progress-linear
                  color="secondary"
                  height="12"
                  indeterminate
                />
              </v-alert>
            </v-card-text>
          </v-expand-transition>

          <v-card-text v-if="generating_success">
            <v-alert color="info" prominent icon="mdi-check-circle" dark text>
              {{ $t("gw.journey.generate.alert_success") }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="
                !source && !generating_success && [0, 1].includes(inputMode)
              "
              text
              color="success"
              small
              :disabled="
                loading ||
                !valid ||
                !title ||
                ([1].includes(inputMode) && !selectedFile)
              "
              @click="addSource()"
            >
              {{ $t("gw.journey.generate.add_content") }}
            </v-btn>
            <v-btn
              v-if="source && !generating_success"
              text
              color="success"
              small
              :disabled="
                loading ||
                !valid ||
                (!licences.ghostwriter.licence.can_overload &&
                  source &&
                  source.cost.journey > licences.ghostwriter.credits_count) ||
                (licences.ghostwriter.licence.can_overload &&
                  source &&
                  source.cost.journey >
                    licences.ghostwriter.credits_count +
                      licences.ghostwriter.licence.credits_overload) ||
                !title ||
                is_generating ||
                (inputMode === 1 &&
                  ((source && !source.body) ||
                    (source && source.body.length === 0))) ||
                ([3, 4].includes(inputMode) &&
                  (!selected_source ||
                    (source && !source.body) ||
                    (source && source.body.length === 0)))
              "
              @click="generateJourney()"
            >
              {{ $t("gw.journey.generate.title") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import PresentationUploadDialog from "@/components/files/PresentationUploadDialog";
import FileSourceDialog from "@/components/files/FileSourceDialog";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwGenerateJourneyDialog",
  components: {
    LanguageChooserInline,
    PresentationUploadDialog,
    FileSourceDialog,
    CountryFlag,
  },
  props: {
    inputText: {
      type: String,
      required: false,
    },
    inputLanguage: {
      type: String,
      required: false,
    },
    inputTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      lang: "de-DE",
      title: "",
      loading: false,
      inputMode: 0, // 0: txt, 1: pdf, 2: video, 3: source, 4: ppt
      txt: "",
      is_generating: false,
      generating_success: false,
      source: null,
      selected_source: null,
      selectedFile: null,
      rules: {
        file: [
          (value) =>
            !value ||
            value.size < 500000000 ||
            this.$t("resources.media.validation", { size: "500" }),
        ],
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
        text: [(v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint")],
      },
      progress: null,
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    ...mapGetters("ghostwriter", {
      sources: "get_sources",
      gw_source: "get_source",
      source_job: "get_job",
    }),
    currentCreditsCount() {
      if (!this.licences || !this.licences.ghostwriter) return "-";
      var credits = this.licences.ghostwriter.credits_count.toLocaleString();
      var pool_string = "";
      if (this.licences.ghostwriter.licence.can_overload) {
        pool_string = ` (${this.$t(
          "gw.licences.credit_pool"
        )}: ${this.licences.ghostwriter.licence.credits_overload.toLocaleString()})`;
      }
      return credits + pool_string;
    },
    filteredSources() {
      var sources = [...this.sources];
      return sources
        .filter((s) => !s.is_deleted && !s.is_archived && s.mimetype !== "file")
        .sort((a, b) => b.id - a.id);
    },
    filteredFileSources() {
      var sources = [...this.sources];
      return sources
        .filter((s) => !s.is_deleted && !s.is_archived && s.mimetype === "file")
        .sort((a, b) => b.id - a.id);
    },
    showPPTFeature() {
      return process.env.VUE_APP_GHOSTWRITER_JOURNEY_PPT === "true";
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        this.lang = this.$i18n.locale;
        if (this.inputText) this.txt = this.inputText;
        if (this.inputLanguage) this.lang = this.inputLanguage;
        if (this.inputTitle) this.title = this.inputTitle;
        this.fetchSources();
      } else {
        this.reset();
      }
    },
    inputMode(v) {
      if (v !== 3) {
        this.source = null;
        this.title = "";
        this.txt = "";
        this.lang = this.$i18n.locale;
        this.selected_source = null;
      } else {
        if (this.selected_source) this.fetchSource(this.selected_source.id);
      }
    },
    selected_source: {
      handler: async function (v) {
        if (!v) this.source = null;
        if (v) {
          await this.fetchSources();
          await this.fetchSource(v.id);
        }
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "add_source",
      "fetch_sources",
      "fetch_source",
      "fetch_job",
      "fetch_ghostwriter_job",
      "fetch_gw_journeys",
      "generate_gw_journey",
      "fetch_sources_generating",
    ]),
    ...mapActions("auth", ["init_auth"]),
    ...mapMutations("ghostwriter", ["set_job"]),
    closeDialog() {
      this.showDialog = false;
      if (this.generating_success) {
        this.fetchJourneys();
      }
      this.reset();
    },
    async fetchJourneys() {
      await this.fetch_gw_journeys();
      await this.fetch_sources_generating();
    },
    async fetchSource(id) {
      if (!id) return false;
      this.loading = true;
      await this.fetch_source({ id: id });
      this.source = { ...this.gw_source };
      this.title = this.gw_source.title;
      this.lang = this.gw_source.lang;
      this.loading = false;
    },
    async addSource() {
      this.loading = true;
      var payload = null;
      if (this.inputMode === 0) {
        payload = {
          txt: this.txt,
          title: this.title,
        };
      }
      if (this.inputMode === 1) {
        let inputs = new FormData();
        inputs.append("file", this.$refs.gwdocument.initialValue);
        inputs.append("title", this.title);
        inputs.append("lang", this.lang);
        payload = inputs;
      }

      if ([0].includes(this.inputMode)) {
        payload.lang = this.lang;
      }

      var res = await this.add_source({
        payload: payload,
        type: this.inputMode === 0 ? "txt" : "pdf", // update after other input options are available
        config:
          this.inputMode === 1
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : null,
      });
      this.loading = false;
      if (
        res &&
        res._status === 200 &&
        res.source.body &&
        res.source.body.length > 0
      ) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.source = res.source;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    reset() {
      this.txt = "";
      this.title = "";
      this.lang = this.$i18n.locale;
      this.source = null;
      this.inputMode = 0;
      this.selected_source = null;
      this.is_generating = false;
      this.generating_success = false;
    },
    openDialog(text) {
      if (text) this.txt = text;
      this.showDialog = true;
    },
    changeLanguage(val) {
      this.lang = val;
    },
    async fetchSources() {
      await this.fetch_sources();
    },
    async generateJourney() {
      if (!this.source) {
        console.error("no source");
        return false;
      }
      this.is_generating = true;
      var res = await this.generate_gw_journey({
        id: this.source.id,
        //title: "test",
      });
      if (res && res._status === 200) {
        this.checkJob(res.source.job.id);
        /* if (res.source.mimetype !== "file") {
          // here
          this.checkJob(res.source.job.id);
        } else {
          this.generating_success = true;
        } */
      }
    },
    async refreshData(id) {
      this.inputMode === 4
        ? await this.fetch_ghostwriter_job({ id: id })
        : await this.fetch_job({
            id: id,
          });

      this.checkJob(id);
    },
    async checkJob(id) {
      this.inputMode === 4
        ? await this.fetch_ghostwriter_job({ id: id })
        : await this.fetch_job({
            id: id,
          });
      if (
        !this.source ||
        !id ||
        !this.showDialog ||
        (this.source_job && this.source_job.status === "SUCCESS") ||
        (this.source_job && this.source_job.status === "FAILURE") ||
        !this.showDialog
      ) {
        this.cancelJob();
        return false;
      }
      if (!this.source || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.refreshData(this.source_job.id);
        }.bind(this),
        2000
      );
    },
    async cancelJob() {
      if (this.source_job && this.source_job.status !== "FAILURE") {
        this.set_job(null);
        await this.init_auth({ id: this.auth.id });
        this.is_generating = false;
        this.generating_success = true;
      }
      this.progress = clearInterval(this.progress);
    },
    setTitle(e) {
      this.title = e;
    },
    setSource(v) {
      this.selected_source = v;
    },
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
  },
};
</script>
